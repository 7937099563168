/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  message,
  Spin,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Main from "../components/layout/Main";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const MyTrainings: React.FC = () => {
  interface Training {
    training: {
      trainingTemplate: { 
        title: string;
        image: string;
        description: string;
        runtime: string;
        heroURL: string | null;
      }
    }
    id: string; 
    completedDate: string;
    status: string;
  }
  
  const [upcomingTrainings, setUpcomingTrainings] = useState<Training[]>([]);
  const [completedTrainings, setCompletedTrainings] = useState<Training[]>([]);
  const [loading, setLoading] = useState(true); 
  const token = localStorage.getItem('jwtToken');
  const navigate = useNavigate();

  const angle = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      key={0}
    >
      <g id="bank" transform="translate(0.75 0.75)">
        <path
          id="Shape"
          transform="translate(0.707 9.543)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path"
          d="M10.25,0,20.5,9.19H0Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path-2"
          data-name="Path"
          d="M0,.707H20.5"
          transform="translate(0 19.793)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>,
  ];

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const currentYear = new Date().getFullYear();
  const placeholderImage = 'https://via.placeholder.com/150';

  useEffect(() => {
    if (!token) {
      throw new Error('Token not found');
     }
    const fetchTrainings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/training/record/`, {
          headers: {
             Authorization: `Bearer ${token}`,
          },
        });
        const trainings = response.data;

        const upcoming = trainings.filter((training: { status: string; }) => training.status !== 'Completed' && training.status !== 'Completed by Proxy');
        const completed = trainings.filter((training: { status: string; }) => training.status === 'Completed' || training.status === 'Completed by Proxy');

        setUpcomingTrainings(upcoming);
        setCompletedTrainings(completed);
      } catch (error) {
        message.error('Error fetching trainings');
      } finally {
        setLoading(false);
      }
    };  

    fetchTrainings();
  },[token]);

  return (
    <>
        <Main>
          <Row gutter={[24, 0]}>
            <Col span={24} md={16} className="mb-24">
              <Card
                className="header-solid h-full"
                bordered={false}
                title={[<h6 className="font-semibold m-0">Upcoming Trainings</h6>]}
                bodyStyle={{ paddingTop: "0" }}
              >
              {loading ? (
                <div className="loading-container">
                  <Spin size="large" /> {/* Render the loading indicator when loadingTemplates is true */}
                </div>
              ) : ( 
                upcomingTrainings.length === 0 ? (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p>All set here! 🎉</p>
                  </div>
                ) : (
                  <Row gutter={[24, 24]}>
                    {upcomingTrainings.map((i, index) => (
                      <Col span={24} key={index}>
                        <Card className="card-billing-info" bordered={true}>
                        <Col span={4} style={{ paddingLeft: 0, paddingRight: '12px' }}>
                          <img
                            src={i.training.trainingTemplate.heroURL ? i.training.trainingTemplate.heroURL : placeholderImage}
                            alt={i.training.trainingTemplate.title}
                            style={{ width: '100%' }}
                          />
                        </Col>
                        <div className="col-info">
                          <Descriptions title={i.training.trainingTemplate.title}>
                          <Descriptions.Item label="Run Time" span={3} labelStyle={{ color: '#8c8c8c' }} contentStyle={{ color: '#8c8c8c' }}>
                              {i.training.trainingTemplate.runtime}
                            </Descriptions.Item>
                            <Descriptions.Item label="" span={3} labelStyle={{ color: '#8c8c8c' }} contentStyle={{ color: '#8c8c8c' }}>
                              {i.training.trainingTemplate.description}
                            </Descriptions.Item>
                          </Descriptions>
                        </div>
                        <div className="col-action">
                            <Button type="link" className="darkbtn">
                              {pencil} Restart 
                            </Button>
                            <Button 
                              type="link" 
                              className="darkbtn"
                              onClick={() => navigate(`/mytrainings/${i.id}`)}>
                              {angle} Start 
                            </Button>
                        </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )
              )} 
              </Card>
            </Col>
            <Col span={24} md={8} className="mb-24">
              <Card
                bordered={false}
                bodyStyle={{ paddingTop: 0 }}
                className="header-solid h-full  ant-list-yes"
                title={<h6 className="font-semibold m-0">Your Certificates</h6>}
              >
                <List
                  header={<h6>{currentYear}</h6>}
                  className="transactions-list ant-newest"
                  itemLayout="horizontal"
                  dataSource={[...completedTrainings.map((training) => ({
                    avatar: <PlusOutlined style={{ fontSize: 10 }} />,
                    title: training.training.trainingTemplate.title,
                    description:  `Completed on ${moment(training.completedDate).format('MM/DD/YYYY')}`,
                    textclass: 'ant-avatar-circle',
                    link: `/training/${training.id}`
                  }))]}
                  renderItem={(item) => (
                    <List.Item 
                        onClick={() => window.location.href = item.link}
                        style={{ cursor: 'pointer' }}
                      >
                      <List.Item.Meta
                        avatar={
                          <Avatar 
                            size="large" 
                            className={item.textclass}
                            style={{ backgroundColor: '#52c41a' }}
                            >
                            {item.avatar}
                          </Avatar>
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Main>
    </>
  );
}

export default MyTrainings;

