import { StreamSend, StreamingAdapterObserver } from '@nlux/react';

// Function to send query to the server and receive a stream of chunks as response
export const send: StreamSend = async (
    prompt: string,
    observer: StreamingAdapterObserver,
) => {
    const body = {prompt};
    const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/chat`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`},
        body: JSON.stringify(body),
    });

    if (response.status !== 200 && response.status !== 201) {
        observer.error(new Error('Failed to connect to the server'));
        return;
    }

    if (!response.body) {
        return;
    }

    // Read a stream of server-sent events
    // and feed them to the observer as they are being generated
    const reader = response.body.getReader();
    const textDecoder = new TextDecoder();

    while (true) {
        const {value, done} = await reader.read();
        if (done) {
            break;
        }

        const content = textDecoder.decode(value);
        if (content) {
            const formattedContent = content.replace(/\\n/g, '<br>');
            observer.next(formattedContent);
        }
    }

    observer.complete();
};
