/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef, useState } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Timeline,
  message,
  Modal,
} from "antd";
import {
  RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import Main from "../components/layout/Main";
import { AiChat, useAsStreamAdapter } from '@nlux/react';
import { send } from '../chatbot/send';
import { personas } from '../chatbot/personas';
import '@nlux/themes/nova.css';
import axios from "axios";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;
const Home: React.FC = () => {

  const token = localStorage.getItem('jwtToken');
  const adapter = useAsStreamAdapter(send, []);
  const [firstEmployeeDate, setFirstEmployeeDate] = useState<Date | null>();
  const [firstTrainingDate, setFirstTrainingDate] = useState<Date | null>();
  const [incidentPublishDate, setIncidentPublishDate] = useState<Date | null>();
  const [onboardingStatus, setOnboardingStatus] = useState<string>();
  const [startSRADate, setStartSRADate] = useState<Date | null>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const aiChartContainerRef = useRef<HTMLDivElement>(null);
  const [conversationStarters, setConversationStarters] = useState([{ 
      label: 'Continue onboarding',
      prompt: 'Let\\s conitnue the onboarding process.'
    },
    {
      label: 'General HIPAA Questions?',
      prompt: 'I have a general HIPAA question.'
    },
    {
      label: 'Issues or pain points',
      prompt: 'General HIPAA Questions?'
    }
  ]);
  const [activeEmployees, setActiveEmployees] = useState<string>('');
  const [newIncidents, setNewIncidents] = useState<string>('');

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill="#fff"
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill="#fff"
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const incidents = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
    <path
    d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
    fill="#fff"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#fff"
    ></path>
    </svg>,
  ];
  const count = [
    {
      today: "Active Employees",
      title: activeEmployees || 0,
      persent: "",
      link: "/employees",
      icon: profile,
      bnb: "bnb2",
    },
    {
      today: "New Incidents",
      title: newIncidents || 0,
      persent: "",
      link: "/incidents",
      icon: incidents,
      bnb: "bnb2",
    },
    {
      today: "Open Tasks",
      title: "5",
      persent: "",
      link: "/assessments",
      icon: dashboard,
      bnb: "redtext",
    }
  ];

  useEffect(() => {
    const container = aiChartContainerRef.current;
    if (!container) return;

    const observer = new MutationObserver(() => {
      container.scrollTop = container.scrollHeight;
    });

    observer.observe(container, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    // Fetch titles from /organization/stats
    const fetchTitles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/organization/stats`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setActiveEmployees(data.employeeCount);
        setNewIncidents(data.inciddentCount);
      } catch (error) {
        message.error('Error fetching titles');
      }
    };

    fetchTitles();
  }, [token]);

  useEffect(() => {
    if (!token) {
      throw new Error('Token not found');
     }
    const fetchTrainings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/organization/`, {
          headers: {
             Authorization: `Bearer ${token}`,
          },
        });
        const organization = response.data;
        console.log(organization);
        setFirstEmployeeDate(organization.firstEmployee ? new Date(organization.firstEmployee) : null);
        setFirstTrainingDate(organization.firstTraining ? new Date(organization.firstTraining) : null);
        setIncidentPublishDate(organization.publishIncident ? new Date(organization.publishIncident) : null);
        setStartSRADate(organization.startSRA ? new Date(organization.startSRA) : null);
        setOnboardingStatus(organization.onboardingStatus);
      } catch (error) {
        message.error('Error fetching trainings');
      }
    };

    fetchTrainings();
  },[token]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_DOMAIN}/organization/incidentPublish/attest`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      message.success('Incident publish attested successfully');
      setIncidentPublishDate(new Date(response.data?.publishIncident));
      setIsModalVisible(false);
    } catch (error) {
      message.error('Error attesting incident publish');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {

    const generateConversationStarters = () => {
      const starters = [];
  
      if (onboardingStatus === 'not-started') {
        starters.push({ label: 'How do I to get started?', prompt: 'MC: How do I to get started?' });
      }
      if (onboardingStatus === 'in-progress') {
        starters.push({ label: 'How do I finish onboarding?', prompt: 'How do I finish onboarding?' });
      }
      if (onboardingStatus === 'completed' || onboardingStatus === 'skipped') {
        starters.push({ label: 'What should I work on today?', prompt: 'MC: What should I work on today inrmedcomply.ai?' });
      }
      starters.push({ label: 'I have general HIPAA questions', prompt: 'MC: I have general HIPAA questions for medcomply.ai?' });
      starters.push({ label: 'I need more info about a feature', prompt: 'I need more info about a feature in medcomply.ai?' });
  
    
      return starters;
    };

    const starters = generateConversationStarters();
    setConversationStarters(starters);
  }, [onboardingStatus, startSRADate]);

  return (
    <>
      <Main>
        <div className="layout-content">

        <Row className="rowgap-vbox" gutter={[24, 0]}>
            {count.map((c, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox ">
                <Link to={c.link}> 
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {c.title} <small className={c.bnb}>{c.persent}</small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box">{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                  </Link>
                </Card>
              </Col>
            ))}
        </Row>
        <Row gutter={[24, 0]} className="homepage-row-height">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Card bordered={false} className="criclebox cardbody h-full">
                  <div className="ai-chart-container"  ref={aiChartContainerRef} >
                  <AiChat
                    adapter={adapter}
                    personaOptions={personas}
                    composerOptions={{
                      placeholder: 'Type here...'
                    }}
                    conversationOptions={{
                      historyPayloadSize: 512,
                      conversationStarters: conversationStarters,
                  }} />
                  </div>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={10} xl={10} className="mb-24">
              <Row gutter={[12, 0]} className="mb-24 common-row">
                  <Card bordered={false} className="criclebox h-full">
                      <div className="timeline-box">
                        <Title level={5}>Steps to getting started</Title>
                        <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                          <span className="bnb2">20%</span>
                        </Paragraph>

                        <Timeline
                          className="timelinelist"
                        >
                          <Timeline.Item color={firstEmployeeDate ? "green" : "red"} >
                            <Title level={5}>{"Add the first employee"}</Title>
                            <Text>
                            {firstEmployeeDate ? (
                              firstEmployeeDate.toDateString()
                            ) : (
                              <>
                                Click <Link to="/employees">here</Link> to complete
                              </>
                            )}
                            </Text>
                          </Timeline.Item>
                          <Timeline.Item color={firstTrainingDate ? "green" : "red"} >
                            <Title level={5}>{"Launch the first compliance training"}</Title>
                            <Text>
                            {firstTrainingDate ? (
                                firstTrainingDate.toDateString()
                              ) : (
                                <>
                                  Click <Link to="/training">here</Link> to complete
                                </>
                              )}
                            </Text>
                          </Timeline.Item>
                          <Timeline.Item color={incidentPublishDate ? "green" : "red"} >
                            <Title level={5}>{"Publish the incident reporting portal"}</Title>
                            <Text>
                            {incidentPublishDate ? (
                                incidentPublishDate.toDateString()
                              ) : (
                                <>
                                  Click <Link to="/incidents/publish">here</Link> to complete, and then <button onClick={showModal} className="link-button">confirm</button>
                                </>
                              )}
                            </Text>
                          </Timeline.Item>
                          <Timeline.Item color={startSRADate ? "green" : "red"} >
                            <Title level={5}>{"Start the security risk assessment"}</Title>
                            <Text>
                            {startSRADate ? (
                                startSRADate.toDateString()
                              ) : (
                                <>
                                  Talk with your Compliance Co-Pilot to get started!
                                </>
                              )}
                            </Text>
                          </Timeline.Item>
                        </Timeline>
                      </div>
                  </Card>
              </Row>
              <Row gutter={[12, 0]} className="common-row">
                  <Card bordered={false} className="criclebox">
                    <div className="gradent h-full col-content">
                      <div className="card-content">
                        <Title level={5}>Work with the best</Title>
                        <p>
                          Wealth creation is an evolutionarily recent positive-sum
                          game. It is all about who take the opportunity first.
                        </p>
                      </div>
                      <div className="card-footer">
                        <a className="icon-move-right" href="#pablo">
                          Read More
                          <RightOutlined />
                        </a>
                      </div>
                    </div>
                  </Card>
              </Row>
            </Col>
        </Row>

          <Modal
            title="Confirm Incident Publish"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Confirm"
            cancelText="Cancel"
          >
            <p>Are you sure you want to confirm the incident publish?</p>
          </Modal>

        </div>
      </Main>
    </>
  );
}

export default Home;
