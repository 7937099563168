/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useCallback, useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  message,
  Modal,
  Table,
  Dropdown,
  Menu,
  Progress,
  Spin,
} from "antd";

import Main from "../components/layout/Main";
import axios from "axios";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";


const Training: React.FC = () => {
  const [trainingTemplates, setTrainingTemplates] = useState<TrainingTemplate[]>([]);
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState<TrainingTemplate | null>(null);
  const [loading, setLoading] = useState(true); 
  const token = localStorage.getItem('jwtToken');

  interface TrainingTemplate {
    id: string;
    description: string | undefined;
    sku: string;
    titlesub: string;
    title: string;
    heroURL: string;
    mediaURL: string;
    runtime: number;
    status: string;
  }

  interface Training {
    id: string;
    trainingTemplateId: string;
    status: string;
    trainingRecords: any[];
    launchDate: string;
    endDate: string;
    type: string;
  }

  const fetchData = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('Token not found');
       }

       const [catalogResponse, organizationResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/training/catalog`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_DOMAIN}/training/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      const trainingTemplates = catalogResponse.data;
      const organizationTrainings = organizationResponse.data;

      setTrainingTemplates(trainingTemplates);
      setTrainings(organizationTrainings);


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  },[token]);

  useEffect(() => { 
    fetchData();
  }, [fetchData]);

  const formatRuntime = (runtime: number) => {
    const hours = Math.floor(runtime / 60);
    const minutes = runtime % 60;
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} min${minutes > 1 ? 's' : ''}` : ''}`;
    }
    return `${minutes} min${minutes > 1 ? 's' : ''}`;
  };

  const showModal = (training: any) => {
    setSelectedTraining(training);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      if (!selectedTraining) {
        throw new Error('No training selected');
      }
      await axios.put(`${process.env.REACT_APP_API_DOMAIN}/training/launch/${selectedTraining.sku}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Training launched successfully');
      // Update the organizationTrainings state to reflect the new "In Progress" status
      setTrainings((prevTrainings) =>
        prevTrainings.map((training) =>
          training.trainingTemplateId === selectedTraining.id ? { ...training, status: 'In Progress' } : training
        )
      );
      fetchData();
    } catch (error) {
      message.error('Error launching training');
    } finally {
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const viewTraining = (user: any) => {
    setSelectedTraining(user);
    setIsModalVisible(true);
  };

  const editTraining = (user: any) => {
    setSelectedTraining(user);
    setIsModalVisible(true);
  };

  const endTraining = (training: any) => {
    Modal.confirm({
      title: 'Are you sure you want to end the training?',
      content: 'This is not reversible.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.put(`${process.env.REACT_APP_API_DOMAIN}/training/close/${training.id}`, {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          message.success('Training ended successfully');
          // Fetch the latest data to reflect the changes
          fetchData();
        } catch (error) {
          message.error('Error ending training');
        }
      },
      onCancel: () => {
        setIsModalVisible(false);
      },
    });
  };

  const columns = [
    {
      title: 'Training',
      dataIndex: 'trainingTemplate',
      key: 'title',
      width: '400px',
      render: (trainingTemplate: any) => (
        <div style={{
          width: '100%',
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
        }}>
          {trainingTemplate.title}
        </div>
      ),
    },
    {
      title: 'Progress',
      dataIndex: '',
      key: 'createdAt',
      render: (text: any, record: any) => {
        const total = record.trainingRecords ? record.trainingRecords.length : 0;
        const completed = record.trainingRecords
        ? record.trainingRecords.filter((r: any) => r.status === 'Completed' || r.status === 'Completed By Proxy').length
        : 0;
        const percent = total > 0 ? (completed / total) * 100 : 0;        
        return (
          <div>
            <Progress percent={percent} />           
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => {
        if (a.status === 'In Progress' && b.status !== 'In Progress') {
          return -1;
        }
        if (a.status !== 'In Progress' && b.status === 'In Progress') {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Launch Date',
      dataIndex: 'launchDate',
      key: 'launchDate',
      render: (text: any) => (
        <div>
          {moment(text).format('MM/DD/YYYY')}
        </div>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text: any) => (
        <div>
          {moment(text).format('MM/DD/YYYY')}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'trainingTemplate',
      key: 'type',
      render: (trainingTemplate: any) => (
        <div style={{ width: '50px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
          {trainingTemplate.type}
        </div>
      )
    }, 
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_: any, record: any) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view" onClick={() => viewTraining(record)}>View</Menu.Item>
              <Menu.Item key="edit" onClick={() => editTraining(record)}>Edit</Menu.Item>
              <Menu.Divider /> 
              <Menu.Item key="close" onClick={() => endTraining(record)}>Close Training</Menu.Item>     
            </Menu>
          }
          trigger={['click']}
        >
          <EllipsisOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    }
  ];

  return (
    <>
      <Main>

        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <>
              <h6 className="font-semibold">Trainings</h6>
              <p>Course Catalog</p>
            </>
          }
        >
          
          <Row gutter={[24, 24]}>
          {loading ? (
            <div className="loading-container">
            </div>
          ) : (
            trainingTemplates.map((t, index) => {
              const isInProgress = trainings.some(
                (training) => training.trainingTemplateId === t.id && training.status === 'In Progress'
              );

              return (
                <Col span={24} md={12} xl={6} key={index}>
                  <Card
                    bordered={false}
                    className="card-project"
                    cover={<img alt="example" src={t.heroURL} />}
                  >
                    <div className="card-tag">{formatRuntime(t.runtime)}</div>
                    <h5>{t.title}</h5>
                    <p>{t.description}</p>
                    <Row gutter={[6, 0]} className="card-footer">
                      <Col span={8}>
                        <Button
                          type="default"
                          style={{ color: '#1890ff', borderColor: '#1890ff' }}
                          onClick={() => showModal(t)}
                          disabled={isInProgress}
                        >
                          {isInProgress ? 'IN PROGRESS' : 'LAUNCH'}
                        </Button>
                      </Col>
                      {!isInProgress && (
                        <Col span={8}>
                          <Button type="default">PREVIEW</Button>
                        </Col>
                      )}
                      <Col span={12} className="text-right">
                        <Avatar.Group className="avatar-chips">
                        </Avatar.Group>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
          }))}
          </Row>
        </Card>
        <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                </div>
              }
            >
              <div className="table-responsive">
              {loading ? (
                <div className="loading-container">
                  <Spin size="large" />
                </div>
              ) : (
                <Table
                  columns={columns}
                  dataSource={trainings}
                  rowKey="id"
                />
              )}
              </div>
            </Card>
      </Main>

      {selectedTraining && (
        <Modal
          title="Confirm Launch"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="OK"
          cancelText="Cancel"
        >
          <p>{selectedTraining.description}</p>
        </Modal>
      )}
    </>
  );
}

export default Training;
